import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When building voice skills for Alexa and Google Home, it’s important to view the messages users are saying to your skill to better understand how they interact and to improve the overall user experience.`}</p>
    <p>{`Think about building something as basic as a weather skill. You may build it to answer the question, “what’s the weather,” but you will quickly find users asking `}<em parentName="p">{`is it hot out`}</em>{`, `}<em parentName="p">{`is it raining`}</em>{`, `}<em parentName="p">{`should I wear a jacket`}</em>{` or `}<em parentName="p">{`bring an umbrella`}</em>{`? Viewing these messages can help identify additional ways users interact or new use cases to support.`}</p>
    <p>{`Having processed over 60 billion messages in conversational interfaces, we took a look at some of the top messages sent into voice devices.`}</p>
    <p>{`One thing to keep in mind is Alexa does not provide the complete raw utterance – i.e. the text of what the user said – whereas Google does. For example, if a user asks Google, “what’s the weather in San Francisco,” as a developer you would get the raw text “what’s the weather in San Francisco” as well as the Intent, “to check weather” and the context, “San Francisco.” If a user said the same thing to Alexa, you would just receive the Intent “check weather” and what they refer to as a “slot” parameter value of location equal to “San Francisco.”`}</p>
    <p>{`The data here is based on common messages said, or sent to, Google Assistant Actions.`}</p>
    <h2>{`Top Unique Messages`}</h2>
    <p>{`The conversational aspects of voice devices tend to be more concise and to the point. The most common individual messages said to Google Home Actions tend to be one word utterances like `}<em parentName="p">{`yes`}</em>{`, `}<em parentName="p">{`no`}</em>{`, and `}<em parentName="p">{`ok`}</em>{`, as well as greetings like `}<em parentName="p">{`hi`}</em>{` and `}<em parentName="p">{`hello`}</em>{`. Many of these messages make sense as they are common answers to questions a voice app may prompt of a user.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/01/06183749/top-voice-unique.png",
        "alt": null
      }}></img></p>
    <h3>{`Greetings`}</h3>
    <p>{`A common type of message said to a voice app is some form of greeting – `}<em parentName="p">{`hi`}</em>{` or `}<em parentName="p">{`hello`}</em>{`. This makes sense as human-to-human communication often starts with some form of greeting, so it’s natural a similar interaction would occur with these devices.`}</p>
    <h3>{`Polar responses`}</h3>
    <p>{`From the top messages list, it’s also apparent polar responses like `}<em parentName="p">{`yes`}</em>{`, `}<em parentName="p">{`no`}</em>{`, `}<em parentName="p">{`ok`}</em>{`, and `}<em parentName="p">{`yeah`}</em>{`, are fairly common. Users do not just say the “standard” `}<em parentName="p">{`yes`}</em>{` or `}<em parentName="p">{`no`}</em>{` either – they say `}<em parentName="p">{`nope`}</em>{`, `}<em parentName="p">{`no no`}</em>{`, `}<em parentName="p">{`yes yes`}</em>{`, `}<em parentName="p">{`no no no`}</em>{`, `}<em parentName="p">{`no thanks`}</em>{`, and more as we will see in the phrase clustering section.`}</p>
    <p>{`In addition to the common greeting and polar responses, there are others that are quite important to handle – messages like `}<em parentName="p">{`help`}</em>{`, `}<em parentName="p">{`stop`}</em>{`, and `}<em parentName="p">{`repeat`}</em>{`.`}</p>
    <h3>{`Help`}</h3>
    <p>{`Providing support for “help” is actually quite helpful. Conversational interfaces are quite new and users may not necessarily know how to interact with your voice app, or know all the functions the app may support. The beauty of conversational interfaces is users tell you `}<em parentName="p">{`what they want`}</em>{` and `}<em parentName="p">{`what they think`}</em>{` of your app. If they are asking for help, it’s important to provide some type of appropriate response – whether that is suggested messages to try, a menu, or some kind of dialog flow to help the user.`}</p>
    <h3>{`Control Functions`}</h3>
    <p>{`As seen in the top list, control phrases like `}<em parentName="p">{`stop`}</em>{` and `}<em parentName="p">{`repeat`}</em>{` are fairly common. Similarly, `}<em parentName="p">{`cancel`}</em>{`, `}<em parentName="p">{`pause`}</em>{`, `}<em parentName="p">{`start over`}</em>{`, `}<em parentName="p">{`end`}</em>{`, `}<em parentName="p">{`continue`}</em>{`, and `}<em parentName="p">{`skip`}</em>{` are also quite common. These are important messages to handle. Users are telling you what they want. If your voice app is too long winded, they will say stop. It’s useful to view these inputs and see what your voice app was doing beforehand that led to these responses and see if there are areas for improvement.`}</p>
    <h3>{`Profanity`}</h3>
    <p>{`As mentioned, users tell you exactly what they think of your voice app – including using profanity. Messages like “f`}{`*`}{` you” and “f`}{`*`}{` off” are pretty common, as well as other unfriendly messages, like “go away.” It’s worthwhile to look at these messages and see what the voice app was doing beforehand that led a user to respond in this way.`}</p>
    <h2>{`Common Phrase Clusters`}</h2>
    <p>{`In addition to the exact text matches above, we applied our data science tools to cluster and examine common phrases and messages voice apps receive.`}</p>
    <h3>{`Device Requests`}</h3>
    <p>{`An interesting finding is users sometimes do not realize they are still within a voice app when they are meaning to talk to the device itself – asking for core functions like `}<em parentName="p">{`what time is it`}</em>{`, `}<em parentName="p">{`what is the weather`}</em>{`, `}<em parentName="p">{`turn off the lights`}</em>{`, `}<em parentName="p">{`play music`}</em>{`, `}<em parentName="p">{`play a game`}</em>{`, or `}<em parentName="p">{`set a timer`}</em>{`. This may also be an issue with the development of the voice app itself – staying “open” for additional user phrases when perhaps one is not warranted or is unlikely.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/01/06183746/device-requests.png",
        "alt": null
      }}></img></p>
    <h3>{`Hand off Back to Device`}</h3>
    <p>{`Related to the above, there are users who request to get back to the core device, and exit the voice app. These users understand they are still within the app and want to get back to the underlying voice assistant. It’s important to handle these messages so the user does not get frustrated.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/01/06183744/hand-offs.png",
        "alt": null
      }}></img></p>
    <h3>{`Stopping`}</h3>
    <p>{`Similar to the one-word control functions that appeared above, phrases indicating the user wants to stop are fairly common. It’s worthwhile viewing what the voice app was doing prior to these messages to see if the app response could be made more concise or relevant.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/01/06183742/stop-messages.png",
        "alt": null
      }}></img></p>
    <h3>{`Help`}</h3>
    <p>{`As we saw in the common unique messages, requests for help are fairly common. It is a new medium and users need help better understanding what the voice app can do. There are a variety of ways users can ask for help, without using the word “help” itself either.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/01/06183741/help-messages.png",
        "alt": null
      }}></img></p>
    <h3>{`Clarity`}</h3>
    <p>{`It’s quite common for voice apps to be asked to repeat a response. It’s not only worthwhile supporting this functionality, but to examine what the prior message was to see if there may be an issue with clarity.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/01/06183739/clarity.png",
        "alt": null
      }}></img></p>
    <h3>{`Polar responses`}</h3>
    <p>{`As we saw above, yes and no responses are fairly common, what’s interesting is so are repetitive forms – “`}<em parentName="p">{`yes yes yes yes`}</em>{`.” It’s worthwhile to dig deeper into transcripts when this occurs to better understand the context – is the user getting frustrated or are they excited?`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/01/06183738/polar-messages.png",
        "alt": null
      }}></img></p>
    <h3>{`User Uncertainty`}</h3>
    <p>{`While users can get frustrated when a device does not understand or know an answer, it’s interesting to see that users sometimes do not have an answer to a voice app’s question either.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/01/06183736/uncertainty.png",
        "alt": null
      }}></img></p>
    <h3>{`User Surprise`}</h3>
    <p>{`Users can be surprised by the responses – in both good and bad ways, depending on context.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/01/06183734/surprise.png",
        "alt": null
      }}></img></p>
    <h3>{`Sentiment`}</h3>
    <p>{`Not only will users tell you what they want from your voice app, they will tell you what they think after – both positive and negative.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/01/06183733/sentiment.png",
        "alt": null
      }}></img></p>
    <h3>{`Personality`}</h3>
    <p>{`Some users treat the voice assistant apps as if they are human, providing an opportunity to give the voice app a personality that could help increase engagement. On a related note, they often ask the voice apps to tell a joke.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/01/06183731/personality.png",
        "alt": null
      }}></img></p>
    <h2>{`Summary`}</h2>
    <p>{`While these are common messages and phrases you may wish to support in your voice assistant app, it’s important to look at the analytics to see what users are asking your particular voice app and make sure you either handle the requests you wish to, or provide a graceful failure response to get the user back on to the “happy path” of what your voice app can do.`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables enterprises and developers to increase engagement, acquisition, and monetization through actionable data and tools.`}</p>
    <p>{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</p>
    <p>{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</p>
    <p>{`We support Alexa, Google Home, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      